import React, { Suspense } from 'react';
import { Router } from 'react-router-dom';
import { Provider } from 'react-redux';
import Pages from '@pages';
import Alert from 'react-s-alert';
import 'react-s-alert/dist/s-alert-css-effects/slide.css';
import ErrorBoundary from './components/misc/ErrorBoundary';

import store from './state/store';

import Loader from '@components/ui-elements/loaders/Loader';

import history from '@/services/history';
const App = () => (
  <div className="font-bebas">
    <Suspense
      fallback={
        <div
          style={{ height: '100vh', width: '100vw' }}
          className="flex justify-center items-center">
          <Loader />
        </div>
      }>
      <Provider store={store}>
        <Router history={history}>
          <ErrorBoundary>
            <Pages />
          </ErrorBoundary>
        </Router>
      </Provider>
      <Alert stack={true} timeout={3000} effect={'slide'} />
    </Suspense>
  </div>
);

export default App;
