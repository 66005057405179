import React from 'react';
import { Link } from 'react-router-dom';

class AxeErrorBoundary extends React.Component {
  state = {
    error: false,
  };

  static getDerivedStateFromError(error) {
    console.log(error);
    return { error: true };
  }

  componentDidCatch(error, info) {
    console.error(error);
    console.error(info);
  }

  redirectToHome = () => {
    this.setState({ error: false });
  };

  render() {
    return this.state.error ? (
      <div className="w-1/4 mx-auto text-center py-12 font-arial">
        <div className="mb-6">
          <i className="fa fa-frown text-white text-5xl text-pink" />
        </div>
        <h2 className="mb-4">
          There is an error in your application. Open the console to gain
          further insight.
        </h2>
        <div>
          <p>
            Go to
            <button onClick={this.redirectToHome}>
              <Link to="/" className="ml-2 underline">
                Home
              </Link>
            </button>
          </p>
        </div>
      </div>
    ) : (
      this.props.children
    );
  }
}

export default AxeErrorBoundary;
